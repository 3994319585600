import React from 'react';
import { Link, FormattedMessage, navigate } from 'gatsby-plugin-intl';
import kebabCase from 'lodash/kebabCase';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';

const HelpItem = ({ title, date, description, to, category, timeToRead }) => {
  return (
    <ListItem
      button
      onClick={() => {
        navigate(to);
      }}
    >
      <ListItemAvatar>
        <Avatar>
          <LiveHelpIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Typography color="textPrimary" component="p" variant="h5">
              {title}
            </Typography>
          </>
        }
        secondary={
          <>
            <Typography component="span" variant="body1">
              {description}
            </Typography>
            <br />
            <FormattedMessage
              id="help.timeToRead"
              defaultMessage="Last updated: {date} - {timeToRead} min Read"
              values={{ date, timeToRead }}
            />
            {/* <Link to={`/help/categories/${kebabCase(category)}`}>
              {' '}
              {category}
            </Link> */}
          </>
        }
      />
    </ListItem>
  );
};

export default HelpItem;
