import { useIntl } from 'gatsby-plugin-intl';

export const paging = ({ url, currentPage, totalPages, navigate }) => {
  const intl = useIntl();
  const pages = [];
  const isFirst = currentPage === 1;
  const isLast = currentPage === totalPages;

  if (!isFirst) {
    const prevPage =
      currentPage - 1 === 1
        ? `/${url}/`
        : `/${url}/${(currentPage - 1).toString()}`;
    pages.push({
      text: intl.formatMessage({ id: 'help.pre', defaultMessage: 'PREV' }),
      onClick: () => {
        navigate(prevPage);
      },
    });
  }
  for (let i = 1; i <= totalPages; i++) {
    const active = i === currentPage;
    pages.push({
      text: i,
      active,
      onClick: () => {
        if (i === 1) {
          navigate(`/${url}/`);
        } else {
          navigate(`/${url}/${i}`);
        }
      },
    });
  }
  if (!isLast) {
    const nextPage = `/${url}/${(currentPage + 1).toString()}`;
    pages.push({
      text: intl.formatMessage({ id: 'help.next', defaultMessage: 'NEXT' }),
      onClick: () => {
        navigate(nextPage);
      },
    });
  }
  return pages;
};
