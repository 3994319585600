/* eslint-disable no-unused-vars */
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  useIntl,
  navigate,
} from 'gatsby-plugin-intl';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';

import { GridContainer } from '@jackywxd/shared-material-ui';
import { GridItem } from '@jackywxd/shared-material-ui';
import { Parallax } from '@jackywxd/shared-material-ui';
import indexStyles from 'assets/jss/material-kit-react/views/landingPage';
import Layout from 'components/Layout';
import Button from 'components/CustomButtons';
import Pagination from 'components/Pagination';
import { paging } from 'components/Utils/paging';
import HelpItem from './HelpItem';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(indexStyles);

const HelpIndex = ({ data, pageContext }) => {
  const classes = useStyles();
  const posts = data.allMarkdownRemark.edges;
  const { currentPage, totalPages } = pageContext;
  const pages = paging({ url: 'help', currentPage, totalPages, navigate });
  const lgScreen = useMediaQuery('(min-width:500px)');
  return (
    <Layout>
      <Parallax small filter style={{ background: 'rgba(66, 133, 243, 0.6)' }}>
        <div className={classes.container}>
          <GridItem>
            <GridContainer justify="center" alignItems="center">
              <h1 className={classes.title} style={{ color: '#fff' }}>
                <FormattedMessage
                  id="help.title"
                  defaultMessage="Help Center"
                />
              </h1>
            </GridContainer>
            {/* <Button
              round
              onClick={() => {
                navigate('/help/tags');
              }}
            >
              <FormattedMessage id="button.byTags" defaultMessage="By Tags" />
            </Button>
            <Button
              round
              onClick={() => {
                navigate('/help/categories');
              }}
            >
              <FormattedMessage
                id="button.byCategories"
                defaultMessage="By categories"
              />
            </Button> */}
          </GridItem>
        </div>
      </Parallax>
      <main
        className={
          lgScreen ? classNames(classes.main, classes.mainRaised) : classes.main
        }
      >
        <GridContainer justify="center" alignItems="center">
          <GridItem xs={12} sm={10} md={8} lg={6}>
            <List>
              {posts.map(({ node }) => {
                return (
                  <HelpItem
                    key={node.fields.slug}
                    to={`/help/${node.fields.slug}`}
                    title={node.frontmatter.title}
                    description={node.frontmatter.description}
                    date={node.frontmatter.date}
                    timeToRead={node.timeToRead}
                    category={node.frontmatter.category}
                  />
                );
              })}
            </List>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {totalPages > 1 ? (
                <Pagination pages={pages} color="primary" />
              ) : null}
            </div>
          </GridItem>
        </GridContainer>
      </main>
    </Layout>
  );
};

HelpIndex.propTypes = {
  data: PropTypes.object.isRequired,
};

export default HelpIndex;

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!, $language: String) {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/help/" }
        frontmatter: { lang: { eq: $language } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            lang
            date(formatString: "DD.MM.YYYY")
            title
            description
            slug
            banner {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            category
            tags
          }
          timeToRead
        }
      }
    }
  }
`;
